/* You can add global styles to this file, and also import other style files */
@import "styles/material-theme";
@import "../../_base-shared/styles/base-shared-styles";

body {
  overflow-x: hidden;
}

.pl-24 {
  padding-left: 24px;
}

.text-inter {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
}

.text-24-inter {
  font-family: 'Inter' !important;
  font-size: 24px;
}

.text-12 {
  font-size: 12px;
  font-family: 'Poppins' !important;
}

.text-14 {
  font-size: 14px;
  font-family: 'Poppins' !important;
}

.text-14-inter {
  font-size: 14px;
  font-family: 'Inter' !important;
}

.text-16-poppins {
  font-size: 16px;
  font-family: 'Poppins' !important;
}

.text-20-poppins {
  font-size: 20px;
  font-family: 'Poppins' !important;
}

.text-22-poppins {
  font-size: 22px;
  font-family: 'Poppins' !important;
}

.text-13-poppins {
  font-size: 13px;
  font-family: 'Poppins' !important;
}

.text-bold-roboto {
  color: #0a6ebd;
  font-size: 14px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: rgba(121, 163, 3) !important;
}

.mat-focused .mat-form-field-appearance-standard .mat-form-field-underline {
  border-bottom: 1px solid rgba(121, 163, 3) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: rgba(121, 163, 3) !important;
}

input {
  color: #666666 !important;
}

.mat-expansion-panel-header {
  height: auto !important;
  min-height: 48px;
}

.mat-content {
  overflow: visible !important;
}

.mat-icon {
  overflow: visible !important;
}

//Overwriting material components
.content-wrapper {
  .mat-horizontal-stepper-header {
    padding: 0 8px;
  }

  .mat-stepper-vertical, .mat-stepper-horizontal {
    background-color: transparent !important;
  }

  .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {

    + .mat-step-label {
      display: none;
      border-radius: 1000px;
      border: 1px solid #0BBFC7;
      /* background: rgba(11, 191, 199, 0.10);*/
    }
  }

  .mat-step-header .mat-step-icon-selected {
    // background-color: $primary;
    display: flex;
    width: 83px;
    height: 8px;
    padding: 3px 24px;
    align-items: flex-start;
    border-radius: 1000px;
    /*  border: 1px solid #3BF099 !important;
      background: #3BF099 !important;*/
    border: 1px solid #0BBFC7;
    background: rgba(11, 191, 199, 0.10) !important;

    + .mat-step-label {
      display: none;
    }
  }

  .mat-step-header .mat-step-label {
    color: rgba(0, 0, 0, .54);
  }

  .mat-step-header .mat-step-icon {
    display: flex;
    width: 83px;
    height: 8px;
    padding: 3px 24px;
    align-items: flex-start;
    border-radius: 1000px;
    border: 1px solid #0BBFC7;
    background: transparent;

    + .mat-step-label {
      display: none;
    }
  }

  .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #3BF099 !important;
    color: #3BF099;
    border: 1px solid #3BF099;
  }

  .mat-step-icon-content {
    display: none !important;
  }

  .mat-step-header:hover {
    background-color: transparent !important;
  }

  .mat-stepper-horizontal-line {
    display: none;
  }

  .mat-step-header.cdk-program-focused {
    background-color: transparent;
  }

  .mat-step-header.cdk-mouse-focused {
    background-color: transparent;
  }

  .mat-step-header.cdk-focused {
    background-color: transparent;
  }


  .mat-form-field-flex {
    width: 212px;
    height: 38px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-bottom: 9px !important;
  }

  .input-full-width .mat-form-field-flex {
    width: 100% !important;
  }

  .mat-form-field-outline {
    background-color: #FFFFFF;
  }

   .mat-step-header {
     pointer-events: none !important;
   }

  .mat-select-arrow-wrapper {
    display: none !important;
  }

  //scrollbar
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
    border-left: 1px solid #E8E8E8;
    background: #FCFCFC;

  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    height: 102.029px;
    border-radius: 100px;
    background: #7A7A7A;
  }

  .mat-horizontal-content-container {
    padding: 8px !important;
  }

  .mat-expansion-panel {
    background: transparent !important;
    border: 1px solid #4d5f7b !important;
    border-radius: 9px !important;
    box-shadow: none !important;
  }

  .mat-content.mat-content-hide-toggle{
    margin-right: 0px !important;
  }

  .mat-expansion-panel-header-title, .mat-expansion-panel-header-description{
    margin-right: 0px !important;
  }

  .mat-expansion-panel-header-description{
    justify-content: end !important;
  }

  .mat-expansion-panel-header-description{
    flex-grow: 0 !important;
  }

  .mat-expansion-panel-body{
    padding: 0px !important;
  }
}

.creditor-wrapper {
  .mat-error {
    position: absolute !important;
    top: 45px !important;
  }
}


@media only screen and (max-width: 800px) {
  .mat-horizontal-stepper-header {
    width: inherit !important;
    padding: 0px !important;
  }
  .contract {
    .mat-stepper-header-position-bottom .mat-horizontal-content-container {
      padding: 7px 25px 0 1px !important;
    }
  }
  .mat-form-field-flex {
    width: 100% !important;
  }

  .mat-horizontal-stepper-header .mat-step-icon {
    margin-right: 13px !important;
  }

  .mat-step-header .mat-step-icon {
    width: 61px !important;
  }
  .mat-horizontal-stepper-header-container {
    padding: 0px !important;
    margin-left: 8px !important;
    margin-right: 5px !important;
  }
}

.payment-desktop-container{
  .mat-radio-label-content{
    font-family: 'Poppins', serif !important;
  }
}
